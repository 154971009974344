<template>
	<svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5 1.5L1 5.5L5 9.5" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>
<script>
  	export default {
    	name: 'IconPrev',
    	props: ['color'],
   	}
</script>